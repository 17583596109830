import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '../Button';

import styles from './ButtonContentWithIcon.module.css';

export const ButtonContentWithIcon = ({
  onClick,
  url,
  label,
  iconSrc,
  disabled,
}) => {
  const handleClick = event => {
    if (disabled) {
      event.preventDefault();
      return;
    }

    onClick && onClick();
  };

  return (
    <Button
      border="normal"
      to={url}
      onClick={handleClick}
      disabled={disabled}
      data-testid="button-content-with-icon"
    >
      <span
        className={styles.content}
        data-testid="button-content-with-icon-label"
      >
        <span>{label}</span>
        <img
          src={iconSrc}
          alt="Icon"
          aria-hidden="true"
          data-testid="button-content-with-icon-image"
        />
      </span>
    </Button>
  );
};

ButtonContentWithIcon.defaultProps = {
  disabled: false,
};

ButtonContentWithIcon.propTypes = {
  url: PropTypes.string,
  label: PropTypes.string,
  iconSrc: PropTypes.string,
  disabled: PropTypes.bool,
};
